<template>
    <div>
      <div class="demo-inline-spacing"><h2>Main Dashboard</h2> <span>(All amounts mentioned are in lacs)</span></div>
      <b-row class="demo-inline-spacing mb-1">
        <b-col col>
          <v-select 
          v-model="yearSelected"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="title"
          :options="yearOptions"
          :reduce="(option) => option.title"
          @input="getYearSelected"
        />
        </b-col>
        <b-col col>
          <div class="d-flex align-items-center">
          <feather-icon
            icon="CalendarIcon"
            size="16"
          />
          <flat-pickr
            v-model="rangePicker"
            :config="flatpickrConfig"
            class="form-control flat-picker bg-transparent border-0 shadow-none"
            placeholder="YYYY-MM-DD"
          />
        </div>
        </b-col> 
        <b-col col>
          <!-- v-if="role === 'ADMIN' || role === 'MASTER' || role === 'HR' || role === 'MIS'" -->
          <v-select 
          v-if="$can('read','manager_filter')"
          v-model="location"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="title"
          :placeholder="'Select Location'"
          :options="locationList"
          :reduce="(option) => option.title"
        />
        </b-col>
        <b-col col>
          <!-- v-if="role === 'ADMIN' || role === 'MASTER' || role === 'ACCOUNT-MANAGER' || role === 'HR' || role === 'MIS'" -->
          <v-select 
            v-if="$can('read','recruiter_filter')"
            v-model="recruiterSelected"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="recruiters"
            :placeholder="'Select Recruiter'"
            :reduce="(option) => option.emp_id"
          />
        </b-col>
        <b-col col>
          <!-- v-if="role === 'ADMIN' || role === 'MASTER' || role === 'ACCOUNT-MANAGER' || role === 'HR' || role === 'MIS'" -->
          <v-select 
            v-if="$can('read','manager_filter')"
            v-model="managerSelected"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="managers"
            :placeholder="'Select Account Manager'"
            :reduce="(option) => option.emp_id"
        />
        </b-col>
        <b-col col>
          <b-button variant="primary"
          v-if="$can('read','recruiter_filter')"
          @click="getMainDashboardData"
        ><feather-icon
            icon="ArrowRightIcon"
            size="16"
          /></b-button>
        </b-col>
        
        
      </b-row>
      <div v-if="error"> {{ error }}</div>
      <b-row >
        <b-col md="3" lg="3">
            
            <b-col md="12" lg="12"> 
                <!-- <recruiter-stack-ranking v-if="rank2 && rank3 && stackRankingDone && stackRanking.length > 0" :role="role" :rank1="rank1" :rank2="rank2" :rank3="rank3" :rank-color="rankColor" :rank1Score="rank1Score"/> -->
                <recruiter-stack-ranking v-if="rank1 && stackRankingDone" :role="role" :rank1="rank1" :rank-color="rankColor" :rank1Score="rank1Score"/>
            </b-col>
            <!-- <b-col md="12" lg="12">
              <b-card>
                <h4 class="mb-1 text-left">
                        Interview Scheduled Today
                    </h4>    
                <b-list-group flush>
                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                    <span>Candidate 1</span>
                    <b-badge
                        variant="light-primary"
                        pill
                        class="badge-round"
                    >
                       10 Jun 2023, 10:30 AM
                    </b-badge>
                    </b-list-group-item>

                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                      <span>Candidate 2</span>
                    <b-badge
                        variant="light-primary"
                        pill
                        class="badge-round"
                    >
                      10 Jun 2023, 12:30 PM
                    </b-badge>
                    </b-list-group-item>

                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                      <span>Candidate 3</span>
                    <b-badge
                        variant="light-primary"
                        pill
                        class="badge-round"
                    >
                      10 Jun 2023, 14:30 PM
                    </b-badge>
                    </b-list-group-item>
                </b-list-group>
            </b-card>
            </b-col>
            <b-col md="12" lg="12">
              <b-card>
                <h4 class="mb-1 text-left">
                        Candidates Joining Today
                    </h4>    
                <b-list-group flush>
                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                    <span>Candidate 1</span>
                    <b-badge
                        variant="light-primary"
                        pill
                        class="badge-round"
                    >
                       Wipro - CCNA                   
                    </b-badge>
                    </b-list-group-item>
                </b-list-group>
            </b-card>
            </b-col>
            <b-col md="12" lg="12">
              <b-card>
                <h4 class="mb-1 text-left">
                        Offered Candidates Follow Up
                    </h4>    
                <b-list-group flush>
                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                    <span>Candidate 1</span>
                    <b-badge
                        variant="light-primary"
                        pill
                        class="badge-round"
                    >
                       Wipro - Network Engineer                   
                    </b-badge>
                    </b-list-group-item>
                </b-list-group>
            </b-card>
            </b-col>
            <b-col md="12" lg="12">
              <b-card>
                <h4 class="mb-1 text-left">
                        Offer Pending Candidates
                    </h4>    
                <b-list-group flush>
                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                    <span>Candidate 1</span>
                    <b-badge
                        variant="light-primary"
                        pill
                        class="badge-round"
                    >
                       Wipro - Network Engineer                   
                    </b-badge>
                    </b-list-group-item>
                </b-list-group>
            </b-card>
            </b-col> -->
            <b-col md="12" lg="12">
                <recruiter-interview-list v-if="interviewList" :interview-list="interviewList" :role="role" :length="interviewList.length"/>
            </b-col>
            <b-col md="12" lg="12">
                <recruiter-joining-data v-if="joiningData" :joining-data="joiningData" :role="role"/>
            </b-col>
        </b-col>
        <b-col md="9" lg="9">
            <b-col md="12" lg="12"> 
                <recruiter-profile-count v-if="profileCountData" :profile-count="JSON.parse(profileCountData)"/>
            </b-col>
            <!-- <b-col md="12" lg="12">
                <recruiter-progress-bar  v-if="progressBarData" :progress-bar-data="progressBarData"/>
            </b-col> -->
            <b-col md="12" lg="12">
                <recruiter-mom v-if="barChartData" :bar-chart-data="barChartData"/>
            </b-col>
            <b-col md="12" lg="12">
                <recruiter-profile-count-graphical  v-if="profileCount" :profile-count="JSON.parse(JSON.stringify(this.profileCountGraphicalData))"/>
            </b-col>
        </b-col>
        
      </b-row>
    </div>
</template>
<script>
import { BRow, BCol, BButton, BCard, BBadge, BListGroup, BListGroupItem } from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

import RecruiterProfileCount from './RecruiterProfileCount.vue'
import RecruiterStackRanking from './RecruiterStackRanking.vue'
import RecruiterProgressBar from './RecruiterProgressBar.vue'
import RecruiterSource from './RecruiterSource.vue'
import RecruiterJoiningData from './RecruiterJoiningData.vue'
import RecruiterInterviewList from './RecruiterInterviewList.vue'
import RecruiterMom from './RecruiterMOM.vue'
import RecruiterProfileCountGraphical from './RecruiterProfileCountGraphical.vue'
import dashboardService from '@/services/dashboard-service'
import userService from '@/services/user-service'
import { $themeColors } from '@themeConfig'
import { resolveVerticalNavMenuItemComponent } from '@/@core/layouts/utils'
//import { toRaw } from 'vue'

export default {
  name: 'Main Dashboard',
  components: {
    BRow,
    BCol,
    BButton,
    vSelect,
    flatPickr,
    RecruiterProfileCount,
    RecruiterStackRanking,
    RecruiterProgressBar,
    RecruiterProfileCountGraphical,
    RecruiterSource,
    RecruiterJoiningData,
    RecruiterInterviewList,
    RecruiterMom,
    BCard, BBadge, BListGroup, BListGroupItem
  },
  data() {
    return {
        yearSelected: '2023-2024',
        yearOptions: [
          {title: "2019-2020"},
          {title: "2020-2021"},
          {title: "2021-2022"},
          {title: "2022-2023"},
        ],
        flatpickrConfig: {
          mode: 'range',
          onChange: this.handleDateChange,
        },
        locationList : [
          
          {"title": "Pune"},
          {"title": "Delhi"}
        ],
        location: '',
        error: '',
        userRole: '',
        role: '',
        userEmpid: '',
        startDate: '',
        endDate: '',
        location: '',
        recruiters: [],
        recruiterSelected: '',
        managers: [],
        managerSelected: '',
        mainData: null,
        profileCountData: null,
        profileCount: null,
        //progressBarData: null,
        barChartData: null,
        rangePicker: '',
        profileCountGraphicalData: null,
        interviewList: null,
        joiningData: null,
        stackRanking: null,
        rank1: null,
        // rank2: null,
        // rank3: null,
        rankColor: null,
        stackRankingDone: false,
        rank1Score: 0

    }
  },
  props: {
        revenueData: {
            type: Array,
            required: false,
        },
  },
  created() {
    this.yearOptions = this.getFinancialYears(2021)

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;

    if (currentMonth >= 4) {
        this.currFinancialYear = currentYear + '-' + (currentYear + 1);
    } else {
        this.currFinancialYear = (currentYear - 1) + '-' + currentYear;
    }

    console.log(this.currFinancialYear);
    this.yearSelected = this.currFinancialYear

    const startYear = this.yearSelected.split("-")[0]
    const endYear = this.yearSelected.split("-").slice(-1)[0]
    this.startDate = startYear + '-04-01'
    this.endDate = endYear + '-03-31'
    console.log('start and end date', this.startDate, this.endDate)
    this.userRole = JSON.parse(localStorage.getItem('userData')).roles[0]
    this.role = this.userRole
    this.userEmpid = JSON.parse(localStorage.getItem('userData')).emp_id
      
    // userService.getAllUsersDdActiveInactive().then(res => {
    //   console.log(res)
    //   if(res.status === 'OK') {
    //     this.recruiters = res.data.filter(item => item.role.toLowerCase() === "TALENT-ADVISOR-SOURCER".toLowerCase() || item.role.toLowerCase() === "ACCOUNT-MANAGER".toLowerCase() || item.role.toLowerCase() === "TALENT-ADVISOR".toLowerCase())

    //   this.managers = res.data.filter(item =>  item.role.toLowerCase() === "ACCOUNT-MANAGER".toLowerCase())
    //   this.recruiters = JSON.parse(JSON.stringify(this.recruiters))
    //   console.log('recruiters', this.recruiters)
    //   } else if ((res.status === 401 ) || (res.status === 403 )) {
    //     window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
    //           this.error = 'Session Expired... Redirecting to Login Page'
    //           localStorage.clear();
    //           this.$router.push({ name: "auth-login" });
    //   }
    //   //this.recruiters = res.data
      
      
      
    // //this.yearOptions = this.yearOptions.push(this.currFinancialYear)
    //   console.log(this.currFinancialYear, this.userEmpid, this.userRole, this.role);
    //   this.yearSelected = this.currFinancialYear
    //   this.getMainDashboardData()
    // })

    userService.getAllTeamMembersList(this.userEmpid).then(res => {
      console.log('recruiters list', res)
      if(res.status === 'OK') {
        this.recruiters = res.data
      // this.managers = res.data.filter(item =>  item.role.toLowerCase() === "ACCOUNT-MANAGER".toLowerCase())
      // this.recruiters = JSON.parse(JSON.stringify(this.recruiters))
      console.log('recruiters', this.recruiters)
      } else if ((res.status === 401 ) || (res.status === 403 )) {
        window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
              this.error = 'Session Expired... Redirecting to Login Page'
              localStorage.clear();
              this.$router.push({ name: "auth-login" });
      }
      console.log(this.currFinancialYear, this.userEmpid, this.userRole, this.role);
      this.yearSelected = this.currFinancialYear
      // this.getMainDashboardData()
    })
    userService.getAllManagersList().then(res => {
      console.log('managers list', res)
      if(res.status === 'OK') {
        this.managers = res.data
      // this.managers = res.data.filter(item =>  item.role.toLowerCase() === "ACCOUNT-MANAGER".toLowerCase())
      // this.recruiters = JSON.parse(JSON.stringify(this.recruiters))
      console.log('managers', this.managers)
      } else if ((res.status === 401 ) || (res.status === 403 )) {
        window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
              this.error = 'Session Expired... Redirecting to Login Page'
              localStorage.clear();
              this.$router.push({ name: "auth-login" });
      }
      console.log(this.currFinancialYear, this.userEmpid, this.userRole, this.role);
      this.yearSelected = this.currFinancialYear
      // this.getMainDashboardData()
    })
    this.getMainDashboardData()
    //this.recruiterSelected = this.userEmpid
    
    

  },
  methods: {
    getMainDashboardData() {
        this.error = ''
        // if (this.userRole === 'ADMIN' || this.userRole === 'MASTER') {
        //   this.userEmpid = null
        // }
        if (this.recruiterSelected) {          
          this.userEmpid = this.recruiterSelected
          //console.log('role', this.userRole, this.userEmpid, this.recruiters, this.recruiters.filter(item => item.emp_id === this.recruiterSelected || item.emp_id === this.userEmpid))
          this.userRole = 'TALENT-ADVISOR-SOURCER'
        } else if (this.managerSelected) {
          //this.userEmpid = null
          this.userEmpid = this.managerSelected
          this.userRole = 'ACCOUNT-MANAGER'
          
        } else {
          //this.userEmpid = null
          this.userRole = this.role
        }
        console.log('Recruiter', this.recruiterSelected, this.userEmpid)
        this.profileCountData = null
        this.profileCount = null
        this.interviewList = null
        this.stackRanking = null
        this.joiningData = null
        //this.progressBarData = null
        this.barChartData = null
        this.profileCountGraphicalData = null
        this.barChartData = null

        dashboardService.getMainDashboard(this.startDate, this.endDate, this.userRole, this.userEmpid, this.location).then(res => {
          console.log(res)
            if(res.status === "OK") {
              this.mainData = res
              this.profileCountData = JSON.stringify(this.mainData.candidateProfileSummary)
              this.profileCount = this.mainData.candidateProfileSummary
              this.stackRanking = this.mainData.stackRanking
              if(this.stackRanking.length > 0) {
                this.computeStackRanking()
              }
              
              console.log('rank1', this.rank1)
              console.log('rank2', this.rank2)
              console.log('rank3', this.rank3)
              this.interviewList = this.mainData.interviewList
              this.joiningData = {
                joiningToday: this.mainData.joiningToday,
                joiningMonth: this.mainData.joiningMonth,
                dojPending: this.mainData.dojPending,
                offerPending: this.mainData.offerPending
              }
              //this.progressBarData =  JSON.stringify(this.mainData.candidateProgressBarData)
              this.barChartData = this.mainData.barChartData
              console.log('bar chart data: ', this.barChartData)
              //console.log('profile count data', this.profileCountData, typeof(this.profileCountData), this.mainData.candidateProfileSummary, typeof(this.mainData.candidateProfileSummary))
              this.profileCountGraphicalData = {
                labels: [`Joined (${this.profileCount.joined})`, `OfferDrop (${this.profileCount.offerDrop})`, `NotOferred (${this.profileCount.notOffered})`, `Offered (${this.profileCount.offered})`, `Selected (${this.profileCount.selected})`, `Rejected (${this.profileCount.rejected})`, `Inprocess (${this.profileCount.inprocess})`, `ScreenReject (${this.profileCount.screenReject})`, `ProfileShared (${this.profileCount.profileShared})`],
                datasets: [
                    {
                    //data: [15, 20, 20, 40, 60, 50, 100, 350, 1000],
                    data: [this.profileCount.joined, this.profileCount.offerDrop, this.profileCount.notOffered, this.profileCount.offered, this.profileCount.selected, this.profileCount.rejected, this.profileCount.inprocess, this.profileCount.screenReject, this.profileCount.profileShared/5],
                    backgroundColor: "#00cfe8",
                    borderColor: 'transparent',
                    barThickness: 10,
                    },
                ],
              }
              //this.profileCountGraphicalData = JSON.stringify(pcData)
              //console.log('candidate details:', this.profileCountData, this.profileCountGraphicalData, JSON.parse(this.progressBarData), JSON.parse(this.barChartData))
              //console.log('candidate details:', JSON.parse(JSON.stringify(this.profileCountGraphicalData)), typeof(this.profileCountGraphicalData))
            } else if ((res.status === 401 ) || (res.status === 403 )) {
              window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
              this.error = 'Session Expired... Redirecting to Login Page'
              localStorage.clear();
              this.$router.push({ name: "auth-login" });
            } else if ((res.status === 400 ) ) {
              this.error = 'Some Error Occurred, Please Try after sometime. If Same Issue occurs multiple times, Kindly contact IT admin.'
            }
            else if ((res.status === 500 ) ) {
              this.error = 'ERROR: ' + res.data.message + 'Kindly contact IT admin.'
            } else {
              this.error = res
            }
          })
    },
    getYearSelected(title) {
        console.log('year selected on change',this.yearSelected, title)
        const startYear = this.yearSelected.split("-")[0]
        const endYear = this.yearSelected.split("-").slice(-1)[0]
        this.startDate = startYear + '-04-01'
        this.endDate = endYear + '-03-31'
        console.log('Selected date:',this.startDate, 'end', this.endDate);
        this.getMainDashboardData(this.startDate, this.endDate, this.userRole, this.userEmpid, this.location)
      },
    getFinancialYears(startYear) {
        const currentYear = new Date().getFullYear();
        
        const years = [];

        for (let year = startYear; year <= currentYear; year++) {
            const financialYearStart = new Date(year, 3, 1); // Assuming financial year starts in April
            const financialYearEnd = new Date(year + 1, 2, 31); // Assuming financial year ends in March
            const financialYear = `${financialYearStart.getFullYear()}-${financialYearEnd.getFullYear()}`;
            years.push({title: financialYear});
        }

        return years;
    },
    handleDateChange() {
        // Perform any desired operations with the selected date
        //         const parts = str.split(" ");
        // const substring = parts.slice(-1)[0];
        console.log('Selected date:',this.rangePicker);
        this.startDate = this.rangePicker.split(" ")[0];
        this.endDate = this.rangePicker.split(" ").slice(-1)[0]
        console.log('Selected date:',this.startDate, 'end', this.endDate);
        this.getMainDashboardData(this.startDate, this.endDate, this.userRole, this.userEmpid, this.location)
    },
    computeStackRanking() {
      console.log('roles', this.userRole, this.role, this.managerSelected, this.recruiterSelected)
      // if  (this.userRole === 'TALENT-ADVISOR-SOURCER' || this.userRole === 'ACCOUNT-MANAGER') {
      if  (this.$can('read','stack_ranking')) {
        //console.log('roles', this.userRole, this.role)
        this.rank1 = this.stackRanking[0]
        this.rankColor = this.rank1.grade === 'Excellent' ? $themeColors.success : this.rank1.grade === 'Very Good' ? $themeColors.primary : this.rank1.grade === 'Good' ? $themeColors.info : this.rank1.grade === 'Average' ? $themeColors.warning : $themeColors.danger
        this.stackRankingDone = true
        this.rank1Score = parseInt(this.rank1.total_score)
      }
      // else if ( this.userRole === 'ACCOUNT-MANAGER' && this.role === 'ADMIN') {
      //   console.log('in account manager')
      //   this.rank1 = this.stackRanking.filter(item => item.rank === 1 && item.emp_type === 'account-manager')[0]
      //   this.rank2 = this.stackRanking.filter(item => item.rank === 2 && item.emp_type === 'account-manager')[0]
      //   //this.rank3 = this.stackRanking.filter(item => item.rank === 3 && item.emp_type === 'account-manager')[0]
      //   this.rankColor = this.rank1.grade === 'Excellent' ? $themeColors.success : this.rank1.grade === 'Very Good' ? $themeColors.primary : this.rank1.grade === 'Good' ? $themeColors.info : this.rank1.grade === 'Average' ? $themeColors.warning : $themeColors.danger
      //   this.rank1Score = parseInt(this.rank1.total_score)
      //   this.stackRankingDone = true
      // } 
      else {
        //console.log('roles', this.userRole, this.role)
        // this.rank1 = this.stackRanking.filter(item => item.rank === 1 && item.emp_type === 'talent-advisor-sourcer')[0]
        this.rank1 = this.stackRanking.filter(item => item.rank === 1 && this.$can('read','stack_ranking'))[0]
        // console.log('rank 1', this.rank1, this.stackRanking.filter(item => item.rank === 1 && item.type === 'talent-advisor-sourcer')[0])
        
        this.rankColor = this.rank1.grade === 'Excellent' ? $themeColors.success : this.rank1.grade === 'Very Good' ? $themeColors.primary : this.rank1.grade === 'Good' ? $themeColors.info : this.rank1.grade === 'Average' ? $themeColors.warning : $themeColors.danger
        this.rankColor = this.rank1.grade === 'Excellent' ? $themeColors.success : this.rank1.grade === 'Very Good' ? $themeColors.primary : this.rank1.grade === 'Good' ? $themeColors.info : this.rank1.grade === 'Average' ? $themeColors.warning : $themeColors.danger
        
        this.rank1Score = parseInt(this.rank1.total_score)
        this.stackRankingDone = true
      }
    }

  },
  updated: function () {
      console.log('called in updated')
      this.$nextTick(function () {
        console.log('called in updated nextTick')
        // Code that will run only after the
        // entire view has been re-rendered
        // let y = null
        const scrollPosition = localStorage.getItem('lastPosition')
        console.log('called in updated nextTick getting localstorage', typeof scrollPosition, scrollPosition)
        if (scrollPosition !== '[object Object]') {
           const y = JSON.parse(scrollPosition).y
           

          setTimeout( () => {
            console.log('called in updated nextTick after settimeout', scrollPosition, y)
            if(y) {
              window.scrollTo(0, y);
            }
          },500);
        }
        
        
      })
    },


}
</script>
<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>