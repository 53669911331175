<template>
    <b-row >
        <b-col md="6" lg="6" >
            <b-card no-body> 
                <b-card-header>
                    <b-card-title>Month on Month Revenue </b-card-title>
                </b-card-header>
            
                <!-- chart -->
                <b-card-body>
                    <chartjs-component-bar-chart
                    :height="300"
                    :data="JSON.parse(JSON.stringify(barChartData.momRevenueBarChartData.data))"
                    :options="chartOptions"
                    />
                </b-card-body>
            </b-card>
        </b-col>
        <b-col md="6" lg="6" >
            <b-card no-body>
                <b-card-header>
                    <b-card-title>Month on Month Joined</b-card-title>
                </b-card-header>
            
                <!-- chart -->
                <b-card-body>
                    <chartjs-component-bar-chart
                    :height="300"
                    :data="JSON.parse(JSON.stringify(barChartData.momJoinedBarChartData.data))"
                    :options="chartOptions"
                    />
                </b-card-body>
            </b-card>
        </b-col>
        <b-col md="6" lg="6" >
            <b-card no-body>
                <b-card-header>
                    <b-card-title>Month on Month Selected</b-card-title>
                </b-card-header>
            
                <!-- chart -->
                <b-card-body>
                    <chartjs-component-bar-chart
                    :height="300"
                    :data="JSON.parse(JSON.stringify(barChartData.momSelectedBarChartData.data))"
                    :options="chartOptions"
                    />
                </b-card-body>
            </b-card>
        </b-col>
        <b-col md="6" lg="6" >
            <b-card no-body>
                <b-card-header>
                    <b-card-title>Month on Month Drop- Revenue</b-card-title>
                </b-card-header>
            
                <!-- chart -->
                <b-card-body>
                    <chartjs-component-bar-chart
                    :height="300"
                    :data="JSON.parse(JSON.stringify(barChartData.momDropBarChartData.data))"
                    :options="chartOptions"
                    />
                </b-card-body>
            </b-card>
        </b-col>
    </b-row>
    
  </template>
  
  <script>
  import {
    BCard, BCardHeader, BCardBody, BCardTitle, BRow, BCol
  } from 'bootstrap-vue'
 
  import ChartjsComponentBarChart from './charts-components/ChartjsComponentBarChart.vue'
  //import chartjsData from './chartjsData'
  import dashboardService from '@/services/dashboard-service'
  //import { $themeColors } from '@themeConfig'
  
  export default {
    components: {
      BCard,
      BCardHeader,
      BCardBody,
      BCardTitle, BRow, BCol,
      ChartjsComponentBarChart,
    },
    data() {
      return {
        // momRevenueData: {},
        // momJoinedData: {},
        // momSelectedData: {},
        // momDropData: {},
        //rangePicker: [],
        chartOptions: {
            "elements": {
                "rectangle": {
                "borderWidth": 2,
                "borderSkipped": "bottom"
                }
            },
            "responsive": true,
            "maintainAspectRatio": false,
            "responsiveAnimationDuration": 500,
            "legend": {
                "display": false
            },
            "tooltips": {
                "shadowOffsetX": 1,
                "shadowOffsetY": 1,
                "shadowBlur": 8,
                "shadowColor": "rgba(0, 0, 0, 0.25)",
                "backgroundColor": "#f6f6f6",
                "titleFontColor": "#4b4b4b",
                "bodyFontColor": "#4b4b4b"
            },
            "scales": {
                "xAxes": [
                {
                    "display": true,
                    "gridLines": {
                    "display": true,
                    "color": "rgba(200, 200, 200, 0.2)",
                    "zeroLineColor": "rgba(200, 200, 200, 0.2)"
                    },
                    "scaleLabel": {
                    "display": false
                    },
                    "ticks": {
                    "fontColor": "#6e6b7b"
                    }
                }
                ],
                "yAxes": [
                {
                    "display": true,
                    "gridLines": {
                    "color": "rgba(200, 200, 200, 0.2)",
                    "zeroLineColor": "rgba(200, 200, 200, 0.2)"
                    },
                    "ticks": {
                    "min": 0,
                    "fontColor": "#6e6b7b"
                    }
                }
                ]
            }
        },
        
        
      }
    },
    props: {
      
      barChartData: {
        type: Object,
        required: false,
      },
      
    },
    created() {
      //this.momRevenueData = 
      console.log('bar char data',  JSON.parse(JSON.stringify(this.barChartData.momRevenueBarChartData.data)))
      
    }
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  </style>
  