<template>
    <b-card>
        
            <h4 class="mb-1 text-left">
                Interview List
            </h4>
        <div v-if="interviewList.length > 0">
            <b-list-group flush>
                <b-list-group-item class="d-flex justify-content-between align-items-center" v-for="(i,index) in interviews"
            :key="index">
            
            <router-link
                :to="{
                name: 'apps-interview-tracker',
                query: { rec_id: i.scheduled_by,
                        startDate: today,
                        endDate: today,
                        name: i.name
                }
                }"
            >                                            
                    <span><strong>{{ i.name }} ({{ i.client }}) </strong><span v-if="role === 'ADMIN' || role === 'MASTER' || role === 'MIS' || role === 'HR'"><br> {{ i.recruiter }}</span></span>
                </router-link>
                    <b-badge style="margin-right: 0, text-align: right"
                        variant="light-info"
                        pill
                        class="badge-round"
                    >
                    {{ i.status }} <br> {{ i.date_time }}
                    </b-badge>
                
                </b-list-group-item>
                <span class="text-center text-info font-weight-bolder mt-1" v-if="length > 3 && !toggle" @click="toggleShowMore"> <feather-icon
                icon="ChevronDownIcon"
                size="20"
              /> Show More </span>
                <span class="text-center text-secondary font-weight-bolder mt-1" v-if="length > 3 && toggle" @click="toggleShowMore"> <feather-icon
                icon="ChevronUpIcon"
                size="20"
              /> Show Less </span>
            </b-list-group>
        </div>
        <div v-else>
            No Interviews Scheduled for Today
        </div>
    </b-card>
</template>
<script>
import { BCard, BCardHeader, BListGroup, BListGroupItem, BBadge } from 'bootstrap-vue'
import moment from 'moment'

export default{
    
    data() {
        return {
            today: null,
            toggle: false,
            interviews: [],
            //showValue: 'Show More'
        }
    },
    props: {
        interviewList: {
            type: Array,
            required: false,
        },
        role: {
            type: String,
            required: true
        },
        length: {
            type: Number,
            required: true
        }
    },
    components: {
        BCard, BListGroup, BListGroupItem, BBadge, BCardHeader
    },
    created() {
        this.today = moment().format('YYYY-MM-DD')
        this.interviews = this.interviewList.slice(0, 3);
    },
    methods: {
        toggleShowMore() {
            console.log('toggle', this.toggle, this.interviews.length, this.interviewList.length)
            this.toggle = !this.toggle
            if(this.toggle) {
                this.interviews = this.interviewList
                //this.showValue = 'Show Less'
            } else {
                this.interviews = this.interviewList.slice(0, 3);
                //this.showValue = 'Show More'
            }
        },
        
    },
}
</script>
<style scoped>

</style>