<template>
    <b-card>
        <b-row class="mx-2 my-2 mb-2">
            <b-col cols="12" class="mb-2">
                <b-card-text class="mb-50">
                    Revenue: {{ JSON.parse(progressBarData).revenue[0].revenue }}
                </b-card-text>
                <b-progress
                    :value="JSON.parse(progressBarData).revenue[0].revenue"
                    :max="JSON.parse(progressBarData).revenue[0].exp_revenue"
                    variant="primary"
                    height="10px"
                    class="mt-25"
                />
            </b-col>
            <b-col cols="12" class="mb-2">
                <b-card-text class="mb-50">
                    Joiners: {{ JSON.parse(progressBarData).joined[0].count }}
                </b-card-text>
                <b-progress
                    :value="JSON.parse(progressBarData).joined[0].count"
                    :max="JSON.parse(progressBarData).joined[0].exp_count"
                    variant="success"
                    height="10px"
                    class="mt-25"
                />
            </b-col>
            <b-col cols="12" class="mb-2">
                <b-card-text class="mb-50">
                    Selections: {{ JSON.parse(progressBarData).selected[0].count }}
                </b-card-text>
                <b-progress
                    :value="JSON.parse(progressBarData).selected[0].count"
                    :max="JSON.parse(progressBarData).selected[0].exp_count"
                    variant="warning"
                    height="10px"
                    class="mt-25"
                />
            </b-col>
            <b-col cols="12" class="mb-2">
                <b-card-text class="mb-50">
                    Number of Profiles(avg): {{ JSON.parse(progressBarData).profileshared[0].count }}
                </b-card-text>
                <b-progress
                    :value="JSON.parse(progressBarData).profileshared[0].count"
                    :max="JSON.parse(progressBarData).profileshared[0].exp_count"
                    variant="info"
                    height="10px"
                    class="mt-25"
                />
            </b-col>
        </b-row>    
    </b-card>
</template>
<script>
import {
  BCard, BCardHeader, BRow, BCol, BCardText, BProgress
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
    components: {
        VueApexCharts,
        BCard,
        BCardHeader,
        BRow,
        BCardText,
        BCol,
        BProgress
    },
    data() {
        return {

        }
    },
    created() {
        console.log('progress bar data', this.progressBarData)
    },
    props: {
        progressBarData: {
            type: String,
            required: false,
        },
    },
    methods: {
        
    },
}


</script>
<style lang="scss">
    
</style>