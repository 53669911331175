<template>
    <b-card no-body>
      <b-card-header>
        <div>
          
          <b-card-title>Candidate Profile Status</b-card-title>
        </div>
        
      </b-card-header>
  
      <!-- chart -->
      <b-card-body>
        <chartjs-component-horizontal-bar-chart
          :height="300"
          :data="profileCount"
          :options="chartjsData.options"
        />
      </b-card-body>
    </b-card>
  </template>
  
  <script>
  import {
    BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle,
  } from 'bootstrap-vue'
  //import flatPickr from 'vue-flatpickr-component'
  import ChartjsComponentHorizontalBarChart from './charts-components/ChartjsComponentHorizontalBarChart.vue'
  import { $themeColors } from '@themeConfig'
  
  export default {
    components: {
      BCard,
      BCardBody,
      BCardHeader,
      BCardTitle,
      BCardSubTitle,
      //flatPickr,
      ChartjsComponentHorizontalBarChart,
    },
    data() {
      return {
        chartjsData: {},
        chartColors: {
            primaryColorShade: '#836AF9',
            yellowColor: '#ffe800',
            successColorShade: '#28dac6',
            warningColorShade: '#ffe802',
            warningLightColor: '#FDAC34',
            infoColorShade: '#299AFF',
            dangerColorShade: '#ea5455',
            greyColor: '#4F5D70',
            blueColor: '#2c9aff',
            blueLightColor: '#84D0FF',
            greyLightColor: '#EDF1F4',
            tooltipShadow: 'rgba(0, 0, 0, 0.25)',
            lineChartPrimary: '#666ee8',
            lineChartDanger: '#ff4961',
            labelColor: '#6e6b7b',
            grid_line_color: 'rgba(200, 200, 200, 0.2)',
        },
        
      }
    },
    props: {
      profileCount: {
            type: Object,
            required: false,
        },
    },
    watch: {
      profileCount: {
        handler(newVal) {
          // Do something with newVal
          this.profileCount = newVal
        },
        immediate: true, // This ensures the watcher will also run when the component is created
      },
    },
    created() {
        console.log('graphical profile count', this.profileCount)
        this.chartjsData =  {
            options: {
              elements: {
                  rectangle: {
                  borderWidth: 2,
                  borderSkipped: 'top',
                  },
              },
              tooltips: {
                  // Updated default tooltip UI
                  shadowOffsetX: 1,
                  shadowOffsetY: 1,
                  shadowBlur: 8,
                  shadowColor: this.chartColors.tooltipShadow,
                  backgroundColor: $themeColors.light,
                  titleFontColor: $themeColors.dark,
                  bodyFontColor: $themeColors.dark,
              },
              responsive: true,
              maintainAspectRatio: false,
              responsiveAnimationDuration: 500,
              legend: {
                  display: false,
              },
              scales: {
                  xAxes: [
                  {
                      display: true,
                      gridLines: {
                      zeroLineColor: this.chartColors.grid_line_color,
                      borderColor: 'transparent',
                      color: this.chartColors.grid_line_color,
                      drawTicks: false,
                      },
                      scaleLabel: {
                      display: true,
                      },
                      ticks: {
                      min: 0,
                      fontColor: this.chartColors.labelColor,
                      },
                  },
                  ],
                  yAxes: [
                  {
                      display: true,
                      gridLines: {
                      display: false,
                      },
                      scaleLabel: {
                      display: true,
                      },
                      ticks: {
                      fontColor: this.chartColors.labelColor,
                      },
                  },
                  ],
              },
            },
            // data: {
            // labels: [`Joined (${this.profileCount})`, `OfferDrop (${this.profileCount.offerDrop})`, `NotOferred (${this.profileCount.notOffered})`, `Offered (${this.profileCount.offered})`, `Selected (${this.profileCount.selected})`, `Rejected (${this.profileCount.rejected})`, `Inprocess (${this.profileCount.inprocess})`, `ScreenReject (${this.profileCount.screenReject})`, `ProfileShared (${this.profileCount.profileShared})`],
            // datasets: [
            //     {
            //     //data: [15, 20, 20, 40, 60, 50, 100, 350, 1000],
            //     data: [this.profileCount.joined, this.profileCount.offerDrop, this.profileCount.notOffered, this.profileCount.offered, this.profileCount.selected, this.profileCount.rejected, this.profileCount.inprocess, this.profileCount.screenReject, this.profileCount.profileShared/10],
            //     backgroundColor: $themeColors.info,
            //     borderColor: 'transparent',
            //     barThickness: 10,
            //     },
            // ],
            // },
        }
    },
  }
  </script>
  
  <style lang="scss">
  //@import '@core/scss/vue/libs/vue-flatpicker.scss';
  </style>
  