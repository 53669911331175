<template>
    <div>
        <b-card v-if="joiningData.joiningToday && joiningData.joiningToday.length > 0">
            <h4 class="mb-1 text-left">
                    Candidates Joining Today
                </h4>    
                <b-list-group flush>
                        <b-list-group-item class="d-flex justify-content-between align-items-center" v-for="(i,index) in joiningToday"
                    :key="index">
                    
                    <router-link
                        :to="{
                        name: 'apps-my-candidates',
                        query: { rec_id: i.recruiter,
                                startDate: today,
                                endDate: today,
                                candidate_id: i.candidate_id,
                                job_id: i.job_id
                        }
                        }"
                    >                                            
                            <span><strong>{{ i.name }} ({{ i.client }}) </strong><span v-if="role === 'ADMIN' || role === 'MASTER' || role === 'MIS' || role === 'HR'"><br> {{ i.rec_name }} ( {{ i.offered_ctc }})</span></span>
                        </router-link>
                            <b-badge style="margin-right: 0, text-align: right"
                                variant="light-info"
                                pill
                                class="badge-round"
                            >
                            {{ i.candidateStatus }}
                            </b-badge>
                        
                        </b-list-group-item>
                        <span class="text-center text-info font-weight-bolder mt-1" v-if="joiningData.joiningToday.length > 3 && !toggleJT" @click="toggleJoiningToday"> <feather-icon
                            icon="ChevronDownIcon"
                            size="20"
                        /> Show More </span>
                            <span class="text-center text-secondary font-weight-bolder mt-1" v-if="joiningData.joiningToday.length > 3 && toggleJT" @click="toggleJoiningToday"> <feather-icon
                            icon="ChevronUpIcon"
                            size="20"
                        /> Show Less </span>
                    </b-list-group>
        </b-card>
        <b-card v-if="joiningData.joiningMonth && joiningData.joiningMonth.length > 0">
            <h4 class="mb-1 text-left">
                    Candidates Joining This Month
                </h4>    
                <b-list-group flush>
                        <b-list-group-item class="d-flex justify-content-between align-items-center" v-for="(i,index) in joiningThisMonth"
                    :key="index">
                    
                    <router-link
                        :to="{
                        name: 'apps-my-candidates',
                        query: { rec_id: i.recruiter,
                                startDate: startDate,
                                endDate: endDate,
                                candidate_id: i.candidate_id,
                                job_id: i.job_id
                        }
                        }"
                    >                                            
                            <span><strong>{{ i.name }} ({{ i.client }}) </strong><span v-if="role === 'ADMIN' || role === 'MASTER' || role === 'MIS' || role === 'HR'"><br> {{ i.rec_name }} ( {{ i.offered_ctc }})</span></span>
                        </router-link>
                            <b-badge style="margin-right: 0, text-align: right"
                                variant="light-info"
                                pill
                                class="badge-round"
                            >
                            {{ i.candidateStatus }}
                            <br> {{ i.joining_date }}
                            </b-badge>
                        
                        </b-list-group-item>
                        <span class="text-center text-info font-weight-bolder mt-1" v-if="joiningData.joiningMonth.length > 2 && !toggleJM" @click="toggleJoiningThisMonth"> <feather-icon
                            icon="ChevronDownIcon"
                            size="20"
                        /> Show More </span>
                            <span class="text-center text-secondary font-weight-bolder mt-1" v-if="joiningData.joiningMonth.length > 2 && toggleJM" @click="toggleJoiningThisMonth"> <feather-icon
                            icon="ChevronUpIcon"
                            size="20"
                        /> Show Less </span>
                    </b-list-group>
        </b-card>
        <b-card v-if="joiningData.dojPending && joiningData.dojPending.length > 0">
            <h4 class="mb-1 text-left">
                    Candidates DOJ Pending
                </h4>    
                <b-list-group flush>
                        <b-list-group-item class="d-flex justify-content-between align-items-center" v-for="(i,index) in joiningDojPending"
                    :key="index">
                    
                    <router-link
                        :to="{
                        name: 'apps-my-candidates',
                        query: { rec_id: i.recruiter,
                                candidate_id: i.candidate_id,
                                job_id: i.job_id
                        }
                        }"
                    >                                            
                            <span><strong>{{ i.name }} ({{ i.client }}) </strong><span v-if="role === 'ADMIN' || role === 'MASTER' || role === 'MIS' || role === 'HR'"><br> {{ i.rec_name }} ( {{ i.offered_ctc }})</span></span>
                        </router-link>
                            <b-badge style="margin-right: 0, text-align: right"
                                variant="light-info"
                                pill
                                class="badge-round"
                            >
                            {{ i.candidateStatus }}
                            <br> {{ i.offered_date }}
                            </b-badge>
                        
                        </b-list-group-item>
                        <span class="text-center text-info font-weight-bolder mt-1" v-if="joiningData.dojPending.length > 2 && !toggleJDP" @click="toggleJoiningDojPending"> <feather-icon
                            icon="ChevronDownIcon"
                            size="20"
                        /> Show More </span>
                            <span class="text-center text-secondary font-weight-bolder mt-1" v-if="joiningData.dojPending.length > 2 && toggleJDP" @click="toggleJoiningDojPending"> <feather-icon
                            icon="ChevronUpIcon"
                            size="20"
                        /> Show Less </span>
                    </b-list-group>
        </b-card>
        <b-card v-if="joiningData.offerPending && joiningData.offerPending.length > 0">
            <h4 class="mb-1 text-left">
                    Candidates Offer Pending
                </h4>    
                <b-list-group flush>
                        <b-list-group-item class="d-flex justify-content-between align-items-center" v-for="(i,index) in joiningOfferPending"
                    :key="index">
                    
                    <router-link
                        :to="{
                        name: 'apps-my-candidates',
                        query: { rec_id: i.recruiter,
                                candidate_id: i.candidate_id,
                                job_id: i.job_id
                        }
                        }"
                    >                                            
                            <span><strong>{{ i.name }} ({{ i.client }}) </strong><span v-if="role === 'ADMIN' || role === 'MASTER' || role === 'MIS' || role === 'HR'"><br> {{ i.rec_name }} </span></span>
                        </router-link>
                            <b-badge style="margin-right: 0, text-align: right"
                                variant="light-info"
                                pill
                                class="badge-round"
                            >
                            {{ i.candidateStatus }}
                            <br> {{ i.selection_date }}
                            </b-badge>
                        
                        </b-list-group-item>
                        <span class="text-center text-info font-weight-bolder mt-1" v-if="joiningData.offerPending.length > 2 && !toggleJOP" @click="toggleJoiningOfferPending"> <feather-icon
                            icon="ChevronDownIcon"
                            size="20"
                        /> Show More </span>
                            <span class="text-center text-secondary font-weight-bolder mt-1" v-if="joiningData.offerPending.length > 2 && toggleJOP" @click="toggleJoiningOfferPending"> <feather-icon
                            icon="ChevronUpIcon"
                            size="20"
                        /> Show Less </span>
                    </b-list-group>
        </b-card>
    </div>
</template>
<script>
import { BCard, BCardHeader, BListGroup, BListGroupItem, BBadge } from 'bootstrap-vue'
import moment from 'moment'
export default{
    
    data() {
        return {
            today: null,
            startDate: null,
            endDate: null,
            toggleJT: false,
            joiningToday: null,
            joiningThisMonth: null,
            toggleJM: false,
            toggleJDP: false,
            joiningDojPending: null,
            toggleJOP:false,
            joiningOfferPending: null

        }
    },
    props: {
        joiningData: {
            type: Object,
            required: false,
        },
        role: {
            type: String,
            required: true
        }
    },
    components: {
        BCard, BListGroup, BListGroupItem, BBadge, BCardHeader
    },
    created() {
        this.today = moment().format('YYYY-MM-DD')
        // Get the first date of this month
        this.startDate = moment().startOf('month').format('YYYY-MM-DD');

        // Get the last date of this month
        this.endDate = moment().endOf('month').format('YYYY-MM-DD');
        this.joiningToday =  this.joiningData.joiningToday.slice(0, 2)
        this.joiningThisMonth = this.joiningData.joiningMonth.slice(0, 2)
        this.joiningDojPending = this.joiningData.dojPending.slice(0, 2)
        this.joiningOfferPending = this.joiningData.offerPending.slice(0, 2)

    },
    methods: {
        toggleJoiningToday() {
            console.log('toggle', this.toggleJT, this.joiningToday.length, this.joiningData.joiningToday.length)
            this.toggleJT = !this.toggleJT
            if(this.toggleJT) {
                this.joiningToday = this.joiningData.joiningToday
                //this.showValue = 'Show Less'
            } else {
                this.joiningToday = this.joiningData.joiningToday.slice(0, 2);
                //this.showValue = 'Show More'
            }
        },
        toggleJoiningDojPending() {
            console.log('toggle', this.toggleJDP, this.joiningDojPending.length, this.joiningData.dojPending.length)
            this.toggleJDP = !this.toggleJDP
            if(this.toggleJDP) {
                this.joiningDojPending = this.joiningData.dojPending
                //this.showValue = 'Show Less'
            } else {
                this.joiningDojPending = this.joiningData.dojPending.slice(0, 2);
                //this.showValue = 'Show More'
            }
        },
        toggleJoiningThisMonth() {
            console.log('toggle', this.toggleJM, this.joiningThisMonth.length, this.joiningData.joiningMonth.length)
            this.toggleJM = !this.toggleJM
            if(this.toggleJM) {
                this.joiningThisMonth = this.joiningData.joiningMonth
                //this.showValue = 'Show Less'
            } else {
                this.joiningThisMonth = this.joiningData.joiningMonth.slice(0, 2);
                //this.showValue = 'Show More'
            }
        },
        toggleJoiningOfferPending() {
            console.log('toggle', this.toggleJOP, this.joiningOfferPending.length, this.joiningData.offerPending.length)
            this.toggleJOP = !this.toggleJOP
            if(this.toggleJOP) {
                this.joiningOfferPending = this.joiningData.offerPending
                //this.showValue = 'Show Less'
            } else {
                this.joiningOfferPending = this.joiningData.offerPending.slice(0, 2);
                //this.showValue = 'Show More'
            }
        }
    },
}
</script>
<style scoped>

</style>