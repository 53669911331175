<template>
    <div>
      <b-row>
        <b-col md="12">
            
          <b-card no-body>
            <b-card-header>
            <h4 class="mb-0 text-center">
                Stack Ranking  
            </h4>
            <span class="text-primary" style="margin-right: 0; text-align: right"><strong>{{ month }}</strong></span>
            <!-- <b-card-text class="font-medium-5 mb-0">
                <feather-icon
                icon="HelpCircleIcon"
                size="21"
                class="text-muted cursor-pointer"
                />
            </b-card-text> -->
            </b-card-header>

            <!-- apex chart -->
            <vue-apex-charts
            type="radialBar"
            height="200"
            :options="rankChart.chartOptions"
            :series="rankChart.series"
            />
            
            
            <b-row class="text-center mx-0" >
                <b-col
                    cols="6"
                    class="border-top border-right border-bottom d-flex align-items-between flex-column py-1"
                >
                    <b-card-text class="text-muted mb-0">
                    Name
                    </b-card-text>
                    <h3 class="font-weight-bolder mb-50" style="font-size: 1.1rem !important;">
                    {{ rank1.emp_name }}
                    </h3>
                </b-col>

                <b-col
                    cols="6"
                    class="border-top d-flex border-bottom align-items-between flex-column py-1"
                >
                    <b-card-text class="text-muted mb-0">
                    Rank
                    </b-card-text>
                    <h3 class="font-weight-bolder mb-0">
                        {{ rank1.rank }} 
                    </h3>
                </b-col>
            </b-row>
            <b-row class="mx-2 my-2 mb-2">
                <b-col cols="12" class="mb-2">
                    <b-card-text class="mb-50">
                        Revenue: {{ rank1.revenue }}
                    </b-card-text>
                    <b-progress
                        :value="rank1.revenue/250000*100"
                        max="100"
                        :variant="rank1.revenue/250000*100 >= 80 ? 'success': rank1.revenue/250000*100 < 80 && rank1.revenue/250000*100 >= 60 ? 'primary' : rank1.revenue/250000*100 < 60 && rank1.revenue/250000*100 > 40 ? 'warning' : 'danger'"
                        height="6px"
                        class="mt-25"
                    />
                </b-col>
                
                <b-col cols="12" class="mb-2">
                    <b-card-text class="mb-50">
                        Number of Profiles: {{ rank1.profiles }}
                    </b-card-text>
                    <b-progress
                        :value="rank1.profiles/10*100"
                        max="100"
                        :variant="rank1.profiles/10*100 >= 80 ? 'success': rank1.profiles/10*100 < 80 && rank1.profiles/10*100 >= 70 ? 'primary' : rank1.profiles/10*100 < 70 && rank1.profiles/10*100 > 50 ? 'warning' : 'danger'"
                        height="6px"
                        class="mt-25"
                    />
                </b-col>
                <b-col cols="12" class="mb-2">
                    <b-card-text class="mb-50">
                        LinkedIn Post(Avg): {{ rank1.linkedin }}
                    </b-card-text>
                    <b-progress
                        :value="rank1.linkedin/1*100"
                        max="100"
                        :variant="rank1.linkedin/1*100 >= 80 ? 'success': rank1.profiles/10*100 < 80 && rank1.profiles/10*100 >= 70 ? 'primary' : rank1.profiles/10*100 < 70 && rank1.profiles/10*100 > 50 ? 'warning' : 'danger'"
                        height="6px"
                        class="mt-25"
                    />
                </b-col>
                <b-col cols="12" class="mb-2">
                    <b-card-text class="mb-50">
                        Closure: {{ rank1.closure }}
                    </b-card-text>
                    <b-progress
                        :value="rank1.closure/4*100"
                        max="100"
                        :variant="rank1.closure/4*100 >= 75 ? 'success': rank1.closure/4*100 < 75 && rank1.closure/4*100 >= 50 ? 'primary' : rank1.closure/4*100 < 50 && rank1.closure/10*100 > 25 ? 'warning' : 'danger'"
                        height="6px"
                        class="mt-25"
                    />
                </b-col>
                <b-col cols="12" class="mb-2">
                    <b-card-text class="mb-50">
                        Quality Drops: {{ rank1.drops }}
                    </b-card-text>
                    <b-progress
                        :value="rank1.drops"
                        max="100"
                        variant="danger"
                        height="6px"
                        class="mt-25"
                    />
                </b-col>
            
                <!-- <b-col cols="12" class="mb-1 py-1 border-top" v-if="role === 'ADMIN' || role === 'MASTER' || role === 'MIS' || role === 'HR'">
                    <b-row class=" font-weight-bolder text-success">
                        <b-col col>#2</b-col>
                        <b-col col>{{ rank2.emp_name }}</b-col>
                        <b-col col class="text-right">{{ rank2.total_score }}</b-col>
                    </b-row>
                </b-col>

                <b-col cols="12" class="mb-1 py-1 border-top" v-if="role === 'ADMIN' || role === 'MASTER' || role === 'MIS' || role === 'HR'">
                    <b-row class=" font-weight-bolder text-info" style="color: #CD7F32">
                        <b-col col>#3</b-col>
                        <b-col col>{{ rank3.emp_name }}</b-col>
                        <b-col col class="text-right">{{ rank3.total_score }}</b-col>
                    </b-row>
                </b-col> -->
                <b-col cols="12" class="mb-0 border-top text-center py-2">
                    <router-link
                    :to="{
                                name: 'apps-stack-ranking',
                                /*query: { month: month }*/
                    }">
                        <span class="text-primary"> Show All</span>
                    </router-link>
                </b-col>
            </b-row> 
            
            </b-card> 
            </b-col>
        </b-row>
        
    </div>

</template>
<script>
import {
  BCard, BCardHeader, BRow, BCol, BCardText, BProgress
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'



export default {
    components: {
        VueApexCharts,
        BCard,
        BCardHeader,
        BRow,
        BCardText,
        BCol,
        BProgress
    },
    data() {
        return {
        goal_overview: {},
        //goalOverviewRadialBar: ,
        month: null,
        //rank1: this.rank1
        }
    },
    created() {
        this.month = new Date().toLocaleString('en-In', { month: 'short', year: 'numeric' }).replace(' ','-');
        console.log(this.rank1)

        this.rankChart = {
            series: [this.rank1Score],
            chartOptions: {
            chart: {
                sparkline: {
                enabled: true,
                },
                dropShadow: {
                enabled: true,
                blur: 3,
                left: 1,
                top: 1,
                opacity: 0.1,
                },
            },
            colors: [this.rankColor],
            plotOptions: {
                radialBar: {
                offsetY: -10,
                startAngle: -150,
                endAngle: 150,
                hollow: {
                    size: '77%',
                },
                track: {
                    background: '#ebe9f1',
                    strokeWidth: '50%',
                },
                dataLabels: {
                    name: {
                    show: false,
                    },
                    value: {
                    color: '#5e5873',
                    fontSize: '2.86rem',
                    fontWeight: '600',
                    },
                },
                },
            },
            fill: {
                type: 'gradient',
                gradient: {
                shade: 'dark',
                type: 'horizontal',
                shadeIntensity: 0.5,
                gradientToColors: [this.rankColor],
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100],
                },
            },
            stroke: {
                lineCap: 'round',
            },
            grid: {
                padding: {
                bottom: 30,
                },
            },
            },
        }
    },
    methods: {
        
    },
    props: {
        // rank2: {
        //     type: Object,
        //     required: false,
        // },
        // rank3: {
        //     type: Object,
        //     required: false,
        // },
        rank1: {
            type: Object,
            required: false,
        },
        rankColor: {
            type: String,
            required: true
        },
        role: {
            type: String,
            required: true
        },
        rank1Score: {
            type: Number,
            required: true
        },
    },
}
</script>
