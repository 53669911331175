<template>
    
    <b-row>
      <!-- <b-col v-for="item in statisticsItems"
          :key="item.icon"
        lg="4"
        sm="6"
      >
        <statistic-card-horizontal
          :icon="item.icon"
          :color="item.color"
          :statistic="item.title"
          :statistic-title="item.subTitle"
        />
      </b-col> -->

      <b-col 
        lg="4"
        sm="6"
      >
        <statistic-card-horizontal
          icon="CpuIcon"
          color="success"
          :statistic= "'₹ ' + profileCount.revenue"
          statistic-title="Revenue"
        />
      </b-col>
      <b-col 
        lg="4"
        sm="6"
      >
        <statistic-card-horizontal
          icon="UserPlusIcon"
          color="success"
          :statistic= profileCount.joined
          statistic-title="Joined"
        />
      </b-col>
      <b-col 
        lg="4"
        sm="6"
      >
        <statistic-card-horizontal
          icon="UserCheckIcon"
          color="primary"
          :statistic= profileCount.selected
          statistic-title="Shortlisted"
        />
      </b-col>
      <b-col 
        lg="4"
        sm="6"
      >
        <statistic-card-horizontal
          icon="UserMinusIcon"
          color="danger"
          :statistic= "profileCount.notOffered + ' (₹ ' + profileCount.notOfferedRevenue +  ')'"
          statistic-title="NotOffered"
        />
      </b-col>
      <b-col 
        lg="4"
        sm="6"
      >
        <statistic-card-horizontal
          icon="UserXIcon"
          color="danger"
          :statistic= "profileCount.offerDrop + ' (₹ ' + profileCount.offerDropRevenue +  ')'"
          statistic-title="OfferDrop"
        />
      </b-col>
      <b-col 
        lg="4"
        sm="6"
      >
        <statistic-card-horizontal
          icon="UsersIcon"
          color="info"
          :statistic= "profileCount.offered + ' (₹ ' + profileCount.offeredRevenue +  ')'"
          statistic-title="Offered"
        />
      </b-col>
     
    </b-row>
</template>
  
  <script>
  import { BCard, BRow, BCol, BProgress } from 'bootstrap-vue'
  import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
  //import VueApexCharts from 'vue-apexcharts'
  //import { $themeColors } from '@themeConfig'
  
  //const $barColor = '#f3f3f3'
  
  export default {
    components: {
      BCard,
      BRow,
      BCol,
      BProgress,
      StatisticCardHorizontal
      //VueApexCharts,
    },
    data() {
      return {
        statisticsItems: []
      }
    },
    props: {
        profileCount: {
            type: Object,
            required: false,
        },
    },
    created() {
        console.log('profile count', this.profileCount)
        this.statisticsItems = [{
          "icon": "CpuIcon",
          "color": "success",
          "title": this.profileCount.revenue,
          "subTitle": "Revenue"
        },
        {
          "icon": "UserPlusIcon",
          "color": "success",
          "title": this.profileCount.joined,
          "subTitle": "Joined"
        },
        {
          "icon": "UserCheckIcon",
          "color": "warning",
          "title": this.profileCount.selected,
          "subTitle": "Shortlisted"
        },
        {
          "icon": "UserMinusIcon",
          "color": "danger",
          "title": this.profileCount.notOffered,
          "subTitle": "NotOffer"
        },
        {
          "icon": "UserXIcon",
          "color": "danger",
          "title": this.profileCount.offerDrop,
          "subTitle": "OfferDrop"
        },
        {
          "icon": "UsersIcon",
          "color": "info",
          "title": this.profileCount.offered,
          "subTitle": "Offered"
        }
      ]
      
    },
    
  }
  </script>
  <style scoped>
  .font-weight-bolder {
    font-size: 1.614rem !important;
    font-weight: 500 !important;
  }
  </style>
  